.landing-page {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.landing-page .div {
  background-image: url(../../../static/img/background.svg);
  background-size: 100% 100%;
  height: 1714px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.landing-page .footer {
  background-color: transparent;
  height: 149px;
  left: 1px;
  position: absolute;
  top: 1567px;
  width: 1440px;
}

.landing-page .overlap-group {
  background-image: url(../../../static/img/group-4.png);
  background-size: 100% 100%;
  height: 147px;
  width: 1439px;
}

.landing-page .footer-text {
  height: 106px;
  left: 36px;
  position: relative;
  top: 22px;
  width: 492px;
}

.landing-page .footer-text-2 {
  height: 106px;
  position: relative;
  width: 494px;
}

.landing-page .contact {
  height: 21px;
  left: 1px;
  position: absolute;
  top: 86px;
  width: 68px;
}

.landing-page .div-wrapper {
  height: 21px;
  position: relative;
  width: 70px;
}

.landing-page .text-wrapper {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .all-rights-reserved {
  height: 21px;
  left: 1px;
  position: absolute;
  top: 48px;
  width: 493px;
}

.landing-page .text-wrapper-2 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .text-wrapper-3 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 28px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .landing-page-bottom {
  height: 618px;
  left: 168px;
  position: absolute;
  top: 880px;
  width: 1102px;
}

.landing-page .contact-us-section {
  height: 618px;
  left: 510px;
  position: absolute;
  top: 0;
  width: 592px;
}

.landing-page .contact-us-text {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 361px;
}

.landing-page .group {
  height: 63px;
  left: 0;
  position: absolute;
  top: 0;
  width: 291px;
}

.landing-page .text-wrapper-4 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 48px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .group-2 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 82px;
  width: 363px;
}

.landing-page .p {
  color: #ff0000;
  font-family: "Roboto Mono", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .contact-us-form {
  height: 451px;
  left: 0;
  position: absolute;
  top: 168px;
  width: 592px;
}

.landing-page .name-input {
  height: 108px;
  left: 0;
  position: absolute;
  top: 0;
  width: 592px;
}

.landing-page .vector {
  height: 69px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 592px;
}

.landing-page .group-3 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 41px;
}

.landing-page .email-input {
  height: 100px;
  left: 0;
  position: absolute;
  top: 140px;
  width: 592px;
}

.landing-page .img {
  height: 69px;
  left: 0;
  position: absolute;
  top: 31px;
  width: 592px;
}

.landing-page .group-4 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 51px;
}

.landing-page .send-message-button {
  height: 53px;
  left: 0;
  position: absolute;
  top: 398px;
  width: 594px;
}

.landing-page .overlap-group-2 {
  background-image: url(../../../static/img/vector-1.svg);
  background-size: 100% 100%;
  height: 53px;
  position: relative;
  width: 592px;
}

.landing-page .text-wrapper-5 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 254px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 17px;
}

.landing-page .frame {
  height: 24px;
  left: 222px;
  position: absolute;
  top: 14px;
  width: 24px;
}

.landing-page .message-input {
  height: 99px;
  left: 0;
  position: absolute;
  top: 268px;
  width: 592px;
}

.landing-page .group-5 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 592px;
}

.landing-page .group-6 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 70px;
}

.landing-page .frame-2 {
  height: 318px;
  left: 0;
  position: absolute;
  top: 214px;
  width: 318px;
}

.landing-page .divider {
  height: 2px;
  left: 104px;
  position: absolute;
  top: 822px;
  width: 1200px;
}

.landing-page .landing-page-top {
  height: 595px;
  left: 77px;
  position: absolute;
  top: 151px;
  width: 1227px;
}

.landing-page .main-text {
  height: 392px;
  left: 0;
  position: absolute;
  top: 44px;
  width: 462px;
}

.landing-page .group-7 {
  height: 392px;
  position: relative;
  width: 464px;
}

.landing-page .overlap-group-wrapper {
  height: 333px;
  left: 0;
  position: absolute;
  top: 0;
  width: 470px;
}

.landing-page .overlap-group-3 {
  height: 333px;
  position: relative;
  width: 462px;
}

.landing-page .text-wrapper-6 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 64px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .text-wrapper-7 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 64px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 83px;
}

.landing-page .text-wrapper-8 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 64px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 166px;
}

.landing-page .text-wrapper-9 {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 64px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 249px;
}

.landing-page .text-wrapper-10 {
  color: #ff0000;
  font-family: "Roboto Mono", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 366px;
}

.landing-page .coming-soon-button {
  height: 53px;
  left: 6px;
  position: absolute;
  top: 476px;
  width: 185px;
}

.landing-page .overlap {
  background-image: url(../../../static/img/vector.svg);
  background-size: 100% 100%;
  height: 69px;
  left: -8px;
  position: relative;
  top: -6px;
  width: 199px;
}

.landing-page .text-wrapper-11 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 67px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 22px;
  white-space: nowrap;
}

.landing-page .frame-3 {
  height: 24px;
  left: 35px;
  position: absolute;
  top: 20px;
  width: 24px;
}

.landing-page .computer-image {
  height: 595px;
  left: 601px;
  position: absolute;
  top: 0;
  width: 626px;
}

.landing-page .overlap-2 {
  height: 597px;
  left: -2px;
  position: relative;
  width: 628px;
}

.landing-page .group-8 {
  height: 597px;
  left: 0;
  position: absolute;
  top: 0;
  width: 594px;
}

.landing-page .speech-bubble {
  background-image: url(../../../static/img/speech-bubble-image.png);
  background-size: 100% 100%;
  height: 191px;
  left: 448px;
  position: absolute;
  top: 19px;
  width: 180px;
}

.landing-page .speech-bubble-text {
  height: 129px;
  left: 40px;
  position: relative;
  top: 11px;
  width: 132px;
}

.landing-page .hello-again-dave-wrapper {
  height: 129px;
  position: relative;
  width: 134px;
}

.landing-page .hello-again-dave {
  color: #ff0000;
  font-family: "Roboto Mono", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 132px;
}

.landing-page .header {
  background-color: transparent;
  background-image: url(../../../static/img/group.png);
  background-size: 100% 100%;
  height: 115px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.landing-page .header-content {
  height: 53px;
  left: 37px;
  position: relative;
  top: 31px;
  width: 623px;
}

.landing-page .frame-4 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 40px;
}

.landing-page .memeworks-studios {
  color: transparent;
  font-family: "Roboto Mono", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 68px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.landing-page .span {
  color: #fcca00;
}

.landing-page .text-wrapper-12 {
  color: #ff7629;
}

.landing-page .text-wrapper-13 {
  color: #ffffff;
}

.landing-page .text-wrapper-14 {
  color: #499c5f;
}

.landing-page .text-wrapper-15 {
  color: #ff0000;
}

.landing-page .text-wrapper-16 {
  color: #44b7b9;
}
